.form-group {
  position: relative;
  min-height: 60px;
  align-items: flex-start;

  & .form-group__input,
  & .form-group__textarea {
    display: flex;
    flex-direction: column;

    border: 0;
    color: #000;
    font-family: "Space mono", sans-serif;
    background-color: #fbfbf9;
    border-radius: 2px;

    margin: 0;
    font-size: 14px;
    line-height: 15px;
    width: 100%;
    outline: none;
    padding: 0 0 0 15.333px;
    height: 40px;
  }

  & .form-group__textarea {
    padding-top: 10px;
    min-height: 100px;
    resize: none;
  }

  & .form-group__input.form-group__input--password {
    width: 67%;
    border-right: 0px;
  }

  & .form-group__input > input {
    outline: none;
    border: 0;
  }

  & .form-group__input > span {
    color: #000;
    font-size: 10px;
    font-weight: 900;
    text-transform: uppercase;
  }

  & .form-group__input[disabled] {
    background-color: #f5f5f5;
  }
}

.form-group__select {
  font-family: 'Space mono', sans-serif;
  display: flex;
  border: 0;
  color: #000;
  background-color: #FBFBF9;
  border-radius: 2px;
  margin: 0;
  font-size: 14px;
  line-height: 15px;
  width: 100%;
  outline: none;
  padding: 0 0 0 15.333px;
  height: 40px;
  justify-content: center;
  align-content: center;
  & select {
    width: 1000%;
    border: none;
    box-shadow: none;
    background: transparent;
    background-image: none;
    -webkit-appearance: none;
  }
  & select:focus {
    outline: none;
  }
}

.form-button-container {
  display: flex;
  justify-content: center;
}

.form-group .ow-datepicker-input { background-color: #FBFBF9 }

.form-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-group .ow-datepicker-input {
  background-color: #fbfbf9;
  width: 100%;
}

.input-error::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: red;
}

.input-error::-moz-placeholder {
  /* Firefox 19+ */
  color: red;
}

.input-error:-ms-input-placeholder {
  /* IE 10+ */
  color: red;
}

.input-error:-moz-placeholder {
  /* Firefox 18- */
  color: red;
}

.form-group__password,
.form-group {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
}

.form-group__errors {
  width: 100%;
  color: red;
  padding: 5px 0 10px;
  font-size: 12px;
}

.radio-container {
  top: 2px;
  display: block;
  position: relative;
  cursor: pointer;
  pointer-events: none;

  input {
    position: absolute;
    opacity: 0;
  }

  input:checked ~ .checkmark:after {
    display: block;
    top: 3px;
    left: 3px;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: rgba(84, 84, 84, .46);
  }

  /* When the radio button is checked, add a blue background */
  .radio-container input:checked ~ .checkmark {
    background-color: #fff;
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #fff;
    border-radius: 100%;
    border: 1px solid rgba(84, 84, 84, .46);
  }

  & ~ .text-option {
    margin-bottom: 20px;

    .text-option-title {
      font-size: 12px;
      font-weight: bold;
      margin: 0 0 0 25px;
    }

    .text-option-explanation {
      font-size: 11px;
      margin: 0 0 0 24px;
      color: #989898;
    }
  }


}

.checkbox-list {
  max-width: 240px;
  margin: 0 auto;
}

.checkbox-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;

  & .checkbox-container {
    margin-right: 20px;
    line-height: 0px;
  }

  & p {
    font-size: 12px;
    margin: 0;
  }
}