@mixin font-family-fallback($font) {
  font-family: '#{$font}', Helvetica, sans-serif !important;
}

.text-bolder {
  font-weight: bold;
}

.text-medium {
  font-weight: 500;
}

.text-normal {
  font-weight: 400;
}

.text-light {
  font-weight: 300;
}

// alignment
.center-text {
  text-align: center;
}

.center-element {
  margin: 0 auto;
}

// margins
.mb-15 {
  margin-bottom: 15px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mt-0 {
  margin: 0;
}

.mt-15 {
  margin-top: 15px;
}
