// .header-container header {
//   padding-bottom: 10px;
// }

.section__title {
  font-size: 25px;
  text-align: center;
  margin-bottom: 60px;
}

.errors {
  width: 100%;
}

.error {
  background-color: rgba(244,67,54, 0.01);
  padding: 1px 8px;
  color: #c62828;
  font-size: 15px;
  margin: 2px 0;
  border: 0.5px solid rgba(198,40,40, 0.3);
  border-radius: 2px;
}
