@import "./../../styles/components/utilities";

.card {
  background-color: #fff;
  padding: 22.66px 14px;
  border-radius: 5px;
  margin: 12px 0;
  border: 1px solid rgba(61,61,61, .12);
}

.card--with-border {
  border: 1px solid rgba(177, 177, 177, .5);
}

.card-header {
  cursor: pointer;
}

.card-content {
  position: relative;
  margin-top: 32px;
  @include font-family-fallback("Space mono");
}

.card-content--compact {
  margin-top: 0;
}

.card-content__summary{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-content__summary .btn-primary--inset {
  margin-top: 30px;
}

.summary-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.summary-titles,
.summary-values {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.summary-titles {
  padding-right: 20px;
}

.summary-group__img img {
  width: 48px;
}


.summary-group__title {
  font-weight: bold;
}

.summary-group__title,
.summary-group__value,
.summary-group__img {
  @include font-family-fallback('Space mono');
  margin: 5px 0;
  font-size: 13px;
}

.summary-values {
  @include font-family-fallback("Light");
  overflow: hidden;
  font-weight: 300;
}

@media (min-width: 600px) {
  .summary-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 55%;
  }
}
