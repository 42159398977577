sucstu-modal {
  display: none;

  .modal {
    /* modal container fixed across whole screen */
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0 20px;

    z-index: 100000;
    overflow: auto;

    .modal-body {
      padding: 30px 20px;
      background: #fff;
      margin: 90px auto 0;
      border-radius: 3px;
      max-width: 400px;
    }
  }

  .modal-background {
    /* modal background fixed across whole screen */
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    /* semi-transparent black  */
    background-color: #000;
    opacity: 0.5;

    /* z-index must be below .modal and above everything else  */
    z-index: 10000;
  }

  .modal-actions {
    display: flex;
    justify-content: center;
    margin-top: 35px;
    flex-wrap: wrap;

    & .btn:first-child {
      // margin-right: 10px;
      margin-bottom: 10px;
    }

    & .btn {
      font-size: 14px;
      width: 100%;
    }
  }
}

body.modal-open {
  /* body overflow is hidden to hide main scrollbar when modal window is open */
  overflow: hidden;
}

@media (min-width: 520px) {
  sucstu-modal {
    .modal {
      padding: 0;
      .modal-body {
        padding: 30px 30px;
      }
    }

    .modal-actions {
      flex-wrap: nowrap;

      & .btn:first-child {
        margin-bottom: 0;
        margin-right: 10px;
      }

      & .btn {
        width: 50%;
      }
    }
  }
}

@media (min-width: 768px) {
  sucstu-modal {
    .modal {
      .modal-body {
        padding: 30px 58px;
        margin-top: 180px;
      }
    }

    .modal-actions {
      & .btn {
        width: auto;
        padding: 0 20px;
      }
    }
  }
}

@media (min-width: 1024px) {

}
