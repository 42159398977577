.btn {
  position: relative;
  text-decoration: none;
  text-align: center;

  border: 0;
  border-radius: 5px;

  display: block;
  padding: 0;
  width: 122px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
}

.btn:hover {
  text-decoration: none;
  color: #000;
  font-weight: 400;
}

.btn-show {
  @include font-family-fallback('Space mono');
  background-color: #C8C8C3;
  width: 33%;
  padding: 0 15.333px;
  height: 40px;
}

.btn-show,
.btn {
  border: 0px;
  color: #fff;
  outline: none;
  font-weight: 300;
}

.btn-primary--inset {
  background-color: transparent;
  color: rgb(57, 57, 57);
  border: 1px solid rgb(57, 57, 57);
    font-family: "Bebas Neue";
}

.btn-primary--green-hover:hover,
.btn-primary--green-hoer:hover,
.btn-primary--green-hover:focus {
  background-color: #5b9f82;
  border: none;
}

.btn.btn-primary--with-hover:hover,
.btn.btn-primary--with-hoer:hover,
.btn.btn-primary--with-hover:focus {
  background-color: #5b9f82 !important;
  color: rgb(57, 57, 57) !important;
  border-color: transparent !important;
}

.btn-primary {
  background-color: rgb(57, 57, 57);
}

.btn-cancel {
  background-color: #f44336;
}

.btn-primary:hover,
.btn-cancel:hover {
  color: #fff;
}

.btn-primary--invalid {
  background-color: #616161;
}

.btn--alt {
  width: 151px;
  height: 42px;
  line-height: 42px;
}

.btn:disabled,
.btn:disabled:hover {
  background-color: rgba(0, 0, 0, .08) !important;
  border: 1px solid rgba(0,0,0,0.2) !important;
  color: #393939 !important;
  font-weight: 300 !important;
}
