/*! Copyright © 2009-2018 Postcode Anywhere (Holdings) Ltd. (http://www.postcodeanywhere.co.uk)
 *
 * Address v3.50
 * Styles for the address control.
 *
 * WEB-1-2 08/03/2018 15:36:00
 */
.pca {
  margin: 0;
  padding: 0;
  position: static;
  border: none;
  box-sizing: content-box;
}

.pca * {
  box-sizing: content-box;
}

.pca .pcatext {
  font: 9pt arial;
  color: #333;
}

.pca .pcalist {
  position: relative;
  list-style-type: none;
  list-style-position: outside;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #fafafa;
}

.pca .pcaitem {
  cursor: pointer;
  position: relative;
  margin: 0;
  padding: 9px 10px 9px 10px;
  padding: 7px 10px;
  line-height: 15px;
  text-align: left;
  white-space: nowrap;
  border: solid 1px transparent;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #e5e5e5;
  border-bottom-color: transparent;
  padding-right: 22px;
  opacity: 0.8;
  filter: alpha(opacity=80);
  color: #000;
}

.pca .pcaselected {
  color: #333;
  background-color: #e5f0ff;
  background-color: #f0f0f0;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #e5e5e5;
  border-bottom-color: transparent;
  opacity: 1;
  filter: alpha(opacity=100);
  color: #000;
}

.pca .pcalastitem {
  border-bottom: none;
}

.pca .pcadisableselect {
  -moz-user-select: none;
  -webkit-user-select: none;
}

.pca .pcaautocomplete {
  margin: 0;
  padding: 0;
  border-width: 1px;
  border-style: solid;
  border-color: #ccc;
  border-bottom: 0;
  background: 0;
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2);
  width: inherit;
  position: absolute;
  z-index: 9999999999;
}

.pca .pcaanchor {
  margin: 0;
  padding: 0;
  border: 0;
  background: 0;
}

.pca .pcaanchor .pcachain {
  position: relative;
}

.pca .pcaautocomplete .pcaheader {
  background-color: #fafafa;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #ccc;
  color: #969696;
}

.pca .pcaautocomplete .pcamessage {
  padding: 8px 10px;
  line-height: 18px;
}

.pca .pcaautocomplete .pcalist {
  height: 244px;
  width: auto;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #ccc;
}

.pca .pcaautocomplete .pcafooter {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #ccc;
  background-color: #fafafa;
  height: 30px;
  box-sizing: content-box;
  min-width: 138px;
  overflow: hidden;
}

.pca .pcanotification {
  background-color: #fafafa;
  padding: 8px 10px 8px 10px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  border-radius: 2px;
  position: absolute;
  z-index: 100000;
  transition: opacity .5s ease-in-out;
  -moz-transition: opacity .5s ease-in-out;
  -webkit-transition: opacity .5s ease-in-out;
  opacity: 1;
  display: none;
}

.pca .pcanotification.pcavisible {
  display: block;
}

.pca .pcanotification.pcafade {
  opacity: 0;
}

.pca .pcanotification.pcaerror {
  background-color: #db7f83;
  color: #fff;
}

.pca .pcaautocomplete .pcafooter .pcamessage {
  font: bold 9pt arial;
  color: #969696;
  margin: 9px 0 0 5px;
  padding: 0;
  position: relative;
  float: right;
}

.pca .pcamodal {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 100000;
}

.pca .pcamodal .pcalist {
  height: 244px;
  width: auto;
  min-width: 200px;
  min-height: 100px;
}

.pca .pcamodal .pcaborder {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #000;
  border-radius: 0px;
  opacity: .20;
  filter: alpha(opacity=20);
}

.pca .pcamodal .pcaframe {
  position: relative;
  margin: 12px;
  background-color: #f5f7f9;
  border-radius: 0px;
  border-width: 1px;
  border-style: solid;
  border-color: #c8cacc;
}

.pca .pcamodal .pcacontent {
  position: relative;
  padding: 15px;
}

.pca .pcamodal .pcacontent .pcainputrow {
  width: 450px;
  text-align: right;
}

.pca .pcamodal .pcacontent .pcainputrow input {
  width: 300px;
  border-width: 1px;
  border-style: solid;
  border-color: #d4d4d4;
  padding: 8px;
  margin: 15px;
  background: #fff;
}

.pca .pcamodal .pcacontent .pcainputrow input:invalid {
  -webkit-box-shadow: 0 0 1.5px 1px #ff0000;
  -moz-box-shadow: 0 0 1.5px 1px #ff0000;
  box-shadow: 0 0 1.5px 1px #ff0000;
}

.pca .pcamodal .pcacontent .pcainputrow .pcahalf {
  width: 126px;
}

.pca .pcamodal .pcacontent .pcainputrow label {
  font: bold 14px arial;
  color: #444444;
}

.pca .pcamodal .pcaheader {
  position: relative;
  top: 0;
  left: 0;
  border-top: 1px solid #e1e3e5;
  border-bottom: 1px solid #c8cacc;
  border-radius: 0px 0px 0 0;
  background: #e1e3e5;
  background: -moz-linear-gradient(top, #e1e3e5 0%, #e1e3e5 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #e1e3e5), color-stop(100%, #e1e3e5));
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e1e3e5', endColorstr='#e1e3e5', GradientType=0);
}

.pca .pcamodal .pcaheader .pcatitle {
  font: bold 18px arial;
  color: #005db9;
  margin: 15px;
}

.pca .pcamodal .pcafooter {
  position: relative;
  bottom: 0;
  left: 0;
  border-top: 1px solid #c8cacc;
  background: #fff;
}

.pca .pcamodal .pcafooter .pcabutton {
  width: 150px;
  margin: 15px;
}

.pca .pcafullscreen {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.pca .pcamask {
  background-color: #000;
  opacity: .40;
  filter: alpha(opacity=40);
  z-index: 100000;
}

.pca .pcabutton {
  font: bold 14px arial;
  color: #444444;
  border: 1px solid #d4d4d4;
  border-radius: 0px;
  padding: 8px;
  cursor: pointer;
  background: #f5f7f9;
  background: -moz-linear-gradient(top, #f5f7f9 0%, #f5f7f9 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #f5f7f9), color-stop(100%, #f5f7f9));
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f5f7f9', endColorstr='#f5f7f9', GradientType=0);
}

.pca .pcatooltip {
  position: absolute;
  z-index: 100000;
}

.pca .pcatooltip .pcabackground {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #000;
  border-radius: 5px;
  opacity: .60;
  filter: alpha(opacity=60);
}

.pca .pcatooltip .pcamessage {
  position: relative;
  padding: 5px;
  font: 9pt arial;
  color: #fff;
}

.pca .pcabutton:hover {
  color: #444444;
}

.pca .pcalink {
  font: bold 9pt arial;
  cursor: pointer;
}

.pca .pcaclear {
  clear: both;
}

.pcaflag {
  width: 14px;
  height: 12px;
  position: absolute;
  font-size: 0;
  background-image: url(https://services.postcodeanywhere.co.uk/images/flags16x16.png);
  -moz-box-shadow: 0 1px 2px rgba(128, 128, 128, 0.3);
  -webkit-box-shadow: 0 1px 2px rgba(128, 128, 128, 0.3);
  box-shadow: 0 1px 2px rgba(128, 128, 128, 0.3);
}

.pca .pcaautocomplete .pcafooter .pcaflagbutton {
  width: 31px;
  height: 21px;
  position: relative;
  float: right;
  padding: 5px 6px;
  cursor: pointer;
  background-image: url(https://services.postcodeanywhere.co.uk/images/icons/flag_clear_down.png);
  background-position: 5px 5px;
  background-repeat: no-repeat;
}

.pca .pcaautocomplete .pcafooter .pcaflagbutton:hover {
  background-image: url(https://services.postcodeanywhere.co.uk/images/icons/flag_clear_down_hover.png);
}

.pca .pcaautocomplete .pcafooter .pcaflagbutton .pcaflag {
  top: 9px;
  left: 9px;
}

.pca .pcaautocomplete .pcaflaglabel {
  position: relative;
  left: 26px;
}

.pca .pcacountrylist .pcafooter .pcaflagbutton {
  background-image: url(https://services.postcodeanywhere.co.uk/images/icons/flag_clear_up.png);
}

.pca .pcacountrylist .pcafooter .pcaflagbutton:hover {
  background-image: url(https://services.postcodeanywhere.co.uk/images/icons/flag_clear_up_hover.png);
}

.pca .pcaexpandable {
  background-image: url('https://services.postcodeanywhere.co.uk/images/icons/captureplus/chevron.png');
  background-repeat: no-repeat;
  background-position-x: 99%;
  background-position-y: 50%;
  background-position: right 7px center;
}

.pca .pcaautocomplete .pcafooter .pcalogo {
  width: 68px;
  height: 15px;
  font-size: 0;
  margin: 7px 5px 8px 11px;
  position: relative;
  float: left;
  background-image: url(https://services.postcodeanywhere.co.uk/images/icons/captureplus/pca_predict_logo.png);
}

.pca .pcaautocomplete .pcafooter .adsylogo {
  width: 78px;
  height: 18px;
  font-size: 0;
  margin: 5px 5px 8px 11px;
  position: relative;
  float: left;
  background-image: url(https://services.postcodeanywhere.co.uk/images/icons/captureplus/Addressy-control-logo.png);
}

.pca .pcaautocomplete .pcafooter .aclogo {
  width: 112px;
  height: 15px;
  font-size: 0;
  margin: 8px 5px 8px 11px;
  position: relative;
  float: left;
  background-image: url(https://services.postcodeanywhere.co.uk/images/icons/captureplus/address_complete_logo_en.png);
}

.pca .pcaautocomplete .pcafooter .aclogo.pcalogofr {
  width: 118px;
  background-image: url(https://services.postcodeanywhere.co.uk/images/icons/captureplus/address_complete_logo_fr.png);
}

.pca .pcaautocomplete .pcafooter .dtlogo {
  width: 100px;
  height: 14px;
  font-size: 0;
  margin: 9px 5px 6px 9px;
  position: relative;
  float: left;
  background-image: url(https://services.postcodeanywhere.co.uk/images/icons/captureplus/dt_express_capture_logo.png);
}

.pca .pcaautocomplete .pcafooter .anlogo {
  width: 129px;
  height: 11px;
  font-size: 0;
  margin: 10px 5px 9px 9px;
  position: relative;
  float: left;
  background-image: url(https://services.postcodeanywhere.co.uk/images/icons/captureplus/address_now_logo.png);
}

.pca .pcaitem .pcadescription {
  color: #888;
  margin-left: 8px;
}
